import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// components
import { AppRoutingModule, appRoutingProviders } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';

// global modules 
import { AngularMaterialModule } from "./shared-modules/angular.material.module";
import { GlobalApiService } from './shared-modules/global-api.service';
import { AuthService } from './shared-modules/services/auth.service';
import { AuthGuard } from './shared-modules/guards/auth.guard';
import { NotAuthGuard } from './shared-modules/guards/notAuth.guard';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PWAService } from './shared-modules/services/pwa-updates.service';
import { SideNavService } from './components/side-nav/side-nav.service';
import { DefaultSettingComponent } from './components/side-nav/default-setting/default-setting.component';
import { GhostLoaderModule } from './components/generic-modules/ghost-loader/ghost-loader.module';
import { SocketService } from './shared-modules/services/chat.service';
import { PushNotificationService } from './shared-modules/services/push-notification.service';
import { GlobeSearchModule } from './components/generic-modules/globe-search/globe-search.module';
import { SidebarComponent } from './admin-components/sidebar/sidebar.component';
import { WebcamModule } from 'ngx-webcam';
import { NumberToWordPipe } from './shared-modules/number-to-word/number-to-word.pipe';
import { CookieService } from 'ngx-cookie-service';
import { GlobeNotifyModule } from './components/generic-modules/globe-notify/globe-notify.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FytNotifyPopupModule } from './components/generic-modules/notify-popup/fyt-notify-popup.module';
import { SubExpiredModule } from './components/generic-modules/sub-expired/sub-expired.module';
import { RestrictionModule } from './components/generic-modules/restriction/restriction.module';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { PackagePopupModule } from './components/package-popup/package-popup.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AssignStaffModule } from './components/assign-staff/assign-staff.module';
import { EmailInvoiceComponent } from './components/email-invoice/email-invoice.component';
// import { NewCalendarComponent } from './admin-components/new-calendar/new-calendar/new-calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NewCalendarComponent } from './admin-components/new-calendar/new-calendar/new-calendar.component';
import { DemoUtilsModule } from "./admin-components/new-calendar/demo-utils/module";
import { AdsUploadComponent } from './admin-components/ads-upload/ads-upload/ads-upload.component';
import { AdminBlogComponent } from './admin-components/admin-blog/admin-blog/admin-blog.component';
import { AdminNotifyComponent } from './admin-components/admin-notify/admin-notify/admin-notify.component';
import { RatingModuleModule } from './components/generic-modules/rating-module/rating-module.module';
// import { DemoUtilsModule } from "./admin-components/new-calendar/demo-utils/module";

// import { MatCarouselModule } from '@ngmodule/material-carousel';

// import { BatchesComponent } from './components/batches/batches/batches.component';
// import { AddEnquiryComponent } from './components/enquiry/add-enquiry/add-enquiry.component';


// import { AppRoutingModule } from './app-routing.module';

// import { BatchComponent } from './components/batches/batch/batch.component';

/**
 * Custom angular notifier options
 */
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12,
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 10000,
    onClick: false,
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 10,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};


@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    DefaultSettingComponent,
    SidebarComponent,
    NumberToWordPipe,
    EmailInvoiceComponent,
    NewCalendarComponent,
    AdsUploadComponent,
    AdminBlogComponent,
    AdminNotifyComponent,
    // AssignStaffComponent,
    // NewCalendarComponent,
    // BatchesComponent,
    // AddEnquiryComponent 
  ],
  providers: [
    appRoutingProviders,
    SideNavService,
    GlobalApiService,
    AuthService,
    AuthGuard,
    NotAuthGuard,
    PWAService,
    SocketService,
    CookieService,
    PushNotificationService,
    Meta
  ],
  exports: [],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    WebcamModule,
    NgxMaterialTimepickerModule,
    NgxDatatableModule,
    NgxChartsModule,
    GhostLoaderModule,
    GlobeSearchModule,
    GlobeNotifyModule,
    RatingModuleModule,
    SubExpiredModule,
    FytNotifyPopupModule,
    RestrictionModule,
    AssignStaffModule,
    PackagePopupModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    MatCardModule,
    // MatCarouselModule,
    NotifierModule.withConfig(customNotifierOptions),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: false }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    DemoUtilsModule
  ]
})
export class AppModule { }
