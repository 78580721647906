import { Injectable } from '@angular/core';
import { GlobalApiService } from '../global-api.service';
import { Observable } from 'rxjs';
import * as socketIO from 'socket.io-client';
import { Event } from 'src/app/models/side-nav.data';

@Injectable()
export class SocketService {
  private url = this.globalService.getSocketUrl();
  private socket: any;

  constructor(
    private globalService: GlobalApiService) {
  }
  initializeSocketIO() {
    this.socket = socketIO(this.url, { transports: ['websocket'] });
  }
  public sendMessage(message): void {
    this.socket.emit('new-message', message);
  }

  public notificationConnection(message): void {
    this.socket.emit('notificationConnection', message);
  }

  public checkPopUpStatus(branchId): void {
    this.socket.emit('checkPopUpStatus', branchId);
  }

  getMessages(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('reply', (data: any) =>
        observer.next(data));
    });
  }

  getAdds(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('new-add', (data: any) => {
        // console.log("NEW AD");
        observer.next(data)
      });
    });
  }

  getSocialPopUp(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('socialPopup', (data: any) => {
        // console.log("NEW AD");
        observer.next(data)
      });
    });
  }

  getNotifications(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('notification', (data: any) =>
        observer.next(data));
    });
  }

  getFeedback(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('gymFeedback', (data: any) =>
        observer.next(data));

    });
  }

  onsucess(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('success', (data: any) =>
        observer.next(data));

    });
  }

  getQrCode(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('wa-qrcode', (data: any) =>
        observer.next(data));
    });
  }
  sessionlogged(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('session-logged', (data: any) =>
        observer.next(data));
    });
  }
  sessionError(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('session-error', (data: any) =>
        observer.next(data));
    });
  }

  public onEvent(event: Event): Observable<any> {
    return new Observable<Event>(observer => {
      this.socket.on(event, () => observer.next());
    });
  }

}
