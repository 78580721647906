import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatingPopUpComponent } from './rating-pop-up/rating-pop-up.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    RatingPopUpComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [RatingPopUpComponent]

})
export class RatingModuleModule { }
