import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RatingModuleService } from '../rating-module.service';

@Component({
  selector: 'fyt-rating-pop-up',
  templateUrl: './rating-pop-up.component.html',
  styleUrls: ['./rating-pop-up.component.css']
})
export class RatingPopUpComponent implements OnInit {
  @Output() onAction = new EventEmitter();
  @Input() callBackfun: (arg: any) => void;
  closeFlag: boolean = false;
  feedbackData: any = {};
  details: any;
  constructor(
    public snackBar: MatSnackBar,
    private ratingModuleService: RatingModuleService
  ) { }

  ngOnInit(): void { 
    this.getLocalData()
  }
  rating: number = 0;  // To store the user's rating
  feedbackText: string = '';  // To store additional feedback
  feedbackReason: string = '';  // To store reason if 1, 2, or 3 stars
  reference: string = '';  // To store reference if 4 or 5 stars
  additionalDetails: string = '';  // To store additional details for specific reasons
  starsArray: number[] = [1, 2, 3, 4, 5];  // Array for the 5 stars

  // Function to handle star clicks and set rating
  rateExperience(starRating: number): void {
    this.rating = starRating;
  }

  getLocalData() {
    let uD = window.localStorage.getItem('userDetails');
    this.details = JSON.parse(uD);
  }
  // Function to submit feedback
  submitFeedback(): void {
    let postObj = {
      adminUserId: this.details.adminUserId,
      remark: this.additionalDetails,
      followUpKey: "Gym-Feedback",
      rating: this.rating,
      reason: this.feedbackReason,
      reference: this.reference

    }
    if (this.rating === 0) {
      this.snackBar.open("Please select a rating.", "", { duration: 5000 });
      return;
    }

    if ((this.rating === 1 || this.rating === 2 || this.rating === 3) && !this.feedbackReason) {
      this.snackBar.open("Please select a reason for your rating.", "", { duration: 5000 });
      return;
    }

    if ((this.rating === 1 || this.rating === 2 || this.rating === 3) && !this.additionalDetails) {
      this.snackBar.open("Please provide additional details for your rating.", "", { duration: 5000 });
      return;
    }

    if ((this.rating === 4 || this.rating === 5) && !this.reference) {
      this.snackBar.open("Please provide a reference for your 4 or 5-star rating.", "", { duration: 5000 });
      return;
    }
    this.ratingModuleService.getGYMFeedback(postObj).subscribe(res => {
      if (res.statusCode == '001') {
        this.feedbackData = res.data
      }
    })

    this.rating = 0;
    this.feedbackText = '';
    this.feedbackReason = '';
    this.additionalDetails = '';
    this.reference = '';
    this.snackBar.open("Thank you for your feedback!", "", { duration: 5000 });
    this.closeDialog();
  }


  closeDialog() {
    this.closeFlag = false;
    this.onAction.emit({})
    this.callBackfun("")
  }

}
