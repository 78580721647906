import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared-modules/services/auth.service';
import { SubExpiredService } from '../sub-expired.service';

@Component({
  selector: 'fyt-sub-expired-popup',
  templateUrl: './sub-expired-popup.component.html',
  styleUrls: ['./sub-expired-popup.component.css']
})
export class SubExpiredPopupComponent implements OnInit {
  @Input() InputObj;
  @Output() OutputObj1 = new EventEmitter<any>();
  renewPlan: boolean = true;
  paydata: any;
  renewPlanData: any;
  url: any;
  loader: boolean = false;
  onlinePayFlag: boolean = false;
  renewPlanFlag: boolean = false;
  gymPlanStatus: boolean = false;
  message: string;
  op = 2000;
  sp = 1000;
  
  constructor(
    public router: Router,
    private authService: AuthService,
    public subExpiredService: SubExpiredService
  ) { }

  ngOnInit() {
    console.log(this.InputObj);

    if (this.InputObj.pendingBalance > 0) this.renewPlan = false;
    this.InputObj.daysRemaining = 15
    this.messageContent();
  }

  goRenewPlan() {
    ;
    this.router.navigate(['/services/features-plan/crm']);
    this.gymPlanStatus = true
  }


  closeDialog(flag) {
    this.OutputObj1.emit({ flag: flag, page: 'subExpiredPopUp' });
  }

  payBalance() {
    this.loader = true;
    let postObj = {
      adminPlanId: this.InputObj.adminPlanId,
      paidAmount: this.InputObj.pendingBalance
    }

    //don't remove paytm payment flow
    // this.subExpiredService.payAllPartialPayment(postObj).subscribe((res) => {
    //   if (res.statusCode == "001") {
    //     this.paydata = res.paytmData;
    //     this.url = res.url;
    //     this.loader = false;
    //     this.onlinePayFlag = true;
    //   } else if (res.statusCode == "002") {
    //     this.loader = false;
    //   }
    // });

    this.subExpiredService.razorpayPartialPayment(postObj).subscribe((res) => {
      if (res.statusCode == "001") {
        this.paydata = res.payload;
        // this.url = res.url;
        this.loader = false;
        this.onlinePayFlag = true;
      } else if (res.statusCode == "002") {
        this.loader = false;
      }
    });
  }

  exit() {
    this.authService.logout();
  }

  onSubmit() {
    this.loader = false;
    this.payWithRazor()
  }


  payWithRazor() {
    console.log("payWithRazor---------------", this.paydata);

    const options: any = {
      key: this.paydata.apiKey,
      amount: this.paydata.paidAmount,
      currency: 'INR',
      name: this.paydata?.companyName,
      description: '',
      // image: './assets/logo.png', // company logo or product image
      order_id: this.paydata.ORDER_ID,
      // callback_url: "http://localhost:5200/pay/companyPaymentResponse",
      modal: {
        escape: false,
      },
      notes: {
      },
      theme: {
        color: '#0c238a'
      }
    };
    options.modal.ondismiss = (() => {
      console.log('Transaction cancelled.');
      options.razorpay_payment_status = 'cancelled';
      this.subExpiredService.razorPayResponse(options).subscribe(res => {
        this.closePaymentModel()

      })
    });

    options.handler = ((response, error) => {
      options.razorpay_order_id = response.razorpay_order_id;
      options.razorpay_payment_id = response.razorpay_payment_id;
      options.razorpay_signature = response.razorpay_signature;
      options.razorpay_payment_status = 'completed';
      console.log(response);
      console.log(options);

      this.subExpiredService.razorPayResponse(options).subscribe(res => {
        this.closePaymentModel()

      })
    });


    const rzp = new this.subExpiredService.nativeWindow.Razorpay(options);
    rzp.open();

    //On Payment failed
    rzp.on('payment.failed', function (response) {
      // console.log({ response });
      options.razorpay_payment_status = "payment_failed";
      this.profileService.razorPayResponse(options).subscribe(res => {
        this.closePaymentModel()
      })
    });
  }

  closePaymentModel() {
    this.onlinePayFlag = false;
    this.renewPlan = false;
    this.closeDialog(true)
  }

  messageContent() {


    if (this.InputObj.daysRemaining == 30) {
      this.message = `You have ${this.InputObj.daysRemaining} Days remaining. Renew now and get software at same amount`
    }
    if (this.InputObj.daysRemaining == 15) {
      const originalPrice = 5000;
      const sealingPrice = 3000;
      const discount = ((originalPrice - sealingPrice) / originalPrice) * 100;
      this.message = ` ${this.InputObj.daysRemaining} Days Left Mega Offer Renew now and get flat ${discount}% discount`
    }
    if (this.InputObj.daysRemaining == 10) {
      this.message = `${this.InputObj.daysRemaining} Days Left Renew now and get software at same amount`
    }
    if (this.InputObj.daysRemaining == 5) {
      this.message = `Warning: Only ${this.InputObj.daysRemaining} days left! Don't Miss the chance Renew now at same price`
    }
    if (this.InputObj.daysRemaining == 3) {
      this.message = `You have ${this.InputObj.daysRemaining} Days remaining. Unbelievable but true You Are the lucky customer..... Click on Renew and unlock the grand discount `
    }
    if (this.InputObj.daysRemaining <= 0) {
      this.message = `Warning: Only ${this.InputObj.daysRemaining} days left! Please Renew Your Subscription to Continue`
    }
  }

}
