import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PWAService } from './shared-modules/services/pwa-updates.service';
import { SwUpdate, SwPush } from "@angular/service-worker";
// import { environment } from "../environments/environment";
import { GlobalApiService } from './shared-modules/global-api.service';
import { CookieService } from 'ngx-cookie-service';

// declare ga as a function to set and sent the events
// declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [CookieService]
})
export class AppComponent {
  isLive = true;
  loading = false;
  lat: number = 0;
  lng: number = 0;
  constructor(
    public router: Router,
    public Pwa: PWAService,
    // private swUpdate: SwUpdate,
    private swPush: SwPush,
    private globalService: GlobalApiService,
    private cookie: CookieService
    // private newsletterService: NewsletterServic
  ) {
    // window.onload = function() {
    //   sessionStorage.setItem('isRefreshed', 'true');
    // };
    // window.onbeforeunload = function() {
    //   if (sessionStorage.getItem('isRefreshed')) {
    //       sessionStorage.removeItem('isRefreshed');
    //       return; 
    //   } else {
    //       localStorage.clear();
    //       return ''; 
    //   }
    // };
    // for check the internet connection
    setInterval(() => {
      this.checkInternetConnetion();
    }, 1);
    this.Pwa.checkForUpdate();
    if (!this.cookie.get('lat')) {
      this.getCurrentLoaction();
    }
    console.log('bid-1111', this.cookie.get('bid'));

    if (!this.cookie.get('bid')) {
      let bid = this.makeid(16)
      console.log('here- bid', bid);
      this.cookie.set('bid', bid, { expires: 365 });
      console.log('bid-2-', this.cookie.get('bid'));
    }

  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  checkInternetConnetion() {
    this.isLive = navigator.onLine;
  }

  // create notification
  subscribeToNotifications() {
    this.swPush.requestSubscription({
      serverPublicKey: this.globalService.VAPID_PUBLIC_KEY
    })
      .then(sub => console.log('notification called', sub))
      .catch(err => console.error("Could not subscribe to notifications", err));


    // subscribe to router events and send page views to Google Analytics
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });

  }

  // Get Current Location Coordinates
  getCurrentLoaction() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.cookie.set('lat', this.lat.toString());
        this.cookie.set('lng', this.lng.toString());
      })
    }
  }

}
